<template>
  <div class="login-dashboard d-flex align-center justify-center">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9 login-dashboard__card"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
              class="d-flex align-center justify-center"
            >
              <div>
                <v-img
                  lazy-src="../../../assets/imgs/logo-mobile.png"
                  height="auto"
                  width="255"
                  src="../../../assets/imgs/logo-mobile.png"
                ></v-img>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
              :class="{'mt-6': $vuetify.breakpoint.smAndDown}"
            >
              <h2>{{ 'dash.login.form.title' | text }}</h2>
              <p>{{ 'dash.login.form.desc' | text }}</p>
              <v-alert
                dismissible
                type="error"
                v-model="isShowAlert"
              >{{ alertMsg }}</v-alert>
              <form-login source="dashboard" @handleAlert="handleAlert"/>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-container>
    <dialog-info ref="dialogInfoToken" :info="infos"/>
  </div>
</template>

<script>
// Libs
import { get } from 'dot-prop'

// Constants
import {
  DASHBOARD_FORGOT_PASSWORD,
} from '@/constants/pages'
import {
  API_TOKEN_FORGOT_PASSWORD,
} from '@/constants/apis'

// Components
import FormLogin from '@/components/form/formLogin.vue'
import DialogInfo from '@/components/common/DialogInfo.vue'

// Utils
import request from '@/utils/request'

  export default {
    name: 'loginDashboard',
    components: {
      FormLogin,
      DialogInfo
    },
    data() {
      return {
        isShowAlert: false,
        alertMsg: '',
        infos: {
          title: '',
          desc: '',
          btn: ''
        }
      }
    },
    created() {
      const token = get(this.$route, 'query.token', '')
      if (token) {
        request(API_TOKEN_FORGOT_PASSWORD, {token}).then(res => {
          const success = res.success ? 'success' : 'fail'
          this.infos.title = `login.dialog.${success}.title`
          this.infos.desc = `login.dialog.${success}.desc`
          this.infos.btn = `login.dialog.${success}.btn`
          this.$refs.dialogInfoToken && this.$refs.dialogInfoToken.show()
          if (!res.success) {
            setTimeout(() => {
              this.$router.push({
                name: DASHBOARD_FORGOT_PASSWORD
              }).catch(() => {})
            }, 5000);
          }
        })
      }

    },
    methods: {
      handleAlert (isShowAlert, msg) {
        this.isShowAlert = isShowAlert
        this.alertMsg = msg
      }
    }
  }
</script>

<style lang="scss" scoped>
.login-dashboard {
  height: 100%;
  &__card {
    max-width: 900px;
    margin: auto;
  }
}
</style>