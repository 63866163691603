<template>
  <validation-observer v-slot="{ invalid }">
    <v-form id="form-login" @submit.prevent="checkLogin">
      <validation-provider
        v-slot="{ errors }"
        name="Email"
        rules="required|email"
      >
        <v-text-field
          label="E-mail"
          placeholder="E-mail"
          v-model="email"
          :error-messages="errors"
          clearable
          type="text"
          outlined
          dense
        ></v-text-field>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="Password"
        rules="required"
      >
        <v-text-field
          label="Password"
          placeholder="Password"
          v-model="password"
          :error-messages="errors"
          clearable
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          outlined
          dense
          @click:append="show = !show"
        ></v-text-field>
      </validation-provider>
      <p>Apakah anda <span class="form-login__link" @click="goToForgetPassword">lupa password?</span></p>
      <div class="d-flex justify-end">
        <v-btn 
          color="primary"
          :min-width="160"
          type="submit"
          form="form-login"
          large
          :disabled="isDisabled || invalid"
        >
          MASUK
        </v-btn>
      </div>
    </v-form>
  </validation-observer>
</template>

<script>
  // Libs
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
  import { required, email } from 'vee-validate/dist/rules'
  import { get } from 'dot-prop'
  import CryptoJS from 'crypto-js'

  // Utils
  import loadscreen from '@/utils/common/loadScreen'
  import request from '@/utils/request'
  
  // Constants
  import {
    DASHBOARD_FORGOT_PASSWORD,
    FORGOT_PASSWORD,
    PROGRAM_STUDY,
    REFERENCE_LIST,
    FORM_LIST,
    LABEL
  } from '@/constants/pages'
  import {
    API_LOGIN
  } from '@/constants/apis'

  export default {
    name: 'FormLogin',
    props: {
      source: {
        type: String,
        required: true
      }
    },
    components: {
      ValidationObserver,
      ValidationProvider
    },
    data () {
      return {
        email: '',
        password: '',
        isError: false,
        isShowAllert: false,
        show: false
      }
    },
    computed: {
      isDisabled () {
        return !this.email || !this.password || this.isError
      }
    },
    mounted () {
      extend('required', required)
      extend('email', email)
    },
    methods: {
      async checkLogin () {
        this.$emit('handleAlert', false, '')
        loadscreen.show()
        const params = {
          email: this.email,
          password: CryptoJS.SHA256(this.password).toString()
        }
        const res = await request(API_LOGIN, params)
        if (res.success) {
          const config = {
            role: get(res, 'role', ''),
            isLogin: true,
            token: get(res, 'access_token', ''),
            expiredAt: get(res, 'expires_in', ''),
            userId: get(res, 'id', '')
          }
          await localStorage.setItem('feConfig', JSON.stringify(config))
          let pathName = PROGRAM_STUDY
          if (config.role === 'admin') {
            pathName = REFERENCE_LIST
          } else if (config.role === 'dosen') {
            pathName = FORM_LIST
          } else if (config.role === 'staff_admin') {
            pathName = LABEL
          }
          this.$router.push({
            name: pathName
          }).catch(() => {})
        } else {
          const message = get(res, 'resultInfo.resultMsg', '')
          this.$emit('handleAlert', true, message)
        }
        loadscreen.hide()
      },
      goToForgetPassword () {
        let pathName = FORGOT_PASSWORD
        if (this.source === 'dashboard') {
          pathName = DASHBOARD_FORGOT_PASSWORD
        }
        this.$router.push({
          name: pathName
        }).catch(() => {})
      }
    }
  }
</script>

<style lang="scss" scoped>
.form-login__link {
  font-weight: bold;
  color: #1E355E;
}
</style>